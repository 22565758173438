import styles from './styles.module.css';
import IconChevronDown from '@/components/Icons/IconChevronDown';

// eslint-disable-next-line
// @ts-ignore
import { tAuth as t } from '@sales-i/utils';

export default function SelectionBox({ selectId, value, list, valueProperty, displayProperty, handleInput }) {
  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.iconChevronDown}>
          <IconChevronDown />
        </div>
        <select
          id={selectId}
          className={styles.selectionBox}
          value={value}
          onChange={handleInput}
        >
          {
            !value && (
              <option
                value=""
              >
                {t('please_select')}
              </option>
            )
          }
          {
            list.map(item => (
              <option
                key={item.id}
                value={item[valueProperty]}
              >
                { item[displayProperty] }
              </option>
            ))
          }
        </select>
      </div>
    </>
  );
}